<template>
  <view-container name="newcompetition">
    <router-view></router-view>
  </view-container>
</template>

<script>
export default {
  name: 'NewCompetition',
  created () {
    this.$store.dispatch('getKindSports')
    this.$store.dispatch('getUsers')
    this.$store.dispatch('getOpponets')
  }
}
</script>
